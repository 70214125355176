import {mapMutations} from "vuex";

//sections

export default {
    name: "heroes",
    components: {},
    data() {
        return {}
    },
    created() {

    },
    computed: {},

    methods: {
        ...mapMutations({})
    }
}
